import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  AutoCompleteField,
  InputDatePicker,
  InputField,
  MultiAutoCompleteField,
  MultiSelectField,
  SelectField,
} from "forms/hoc/formfield";
import useForm from "forms/hooks/useForm";
import { useNavigate, useParams } from "react-router-dom";
import { JobPostValidation } from "forms/hooks/validateRules";
import dayjs from "dayjs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./index.scss";
import useScrollspy from "forms/hooks/useScrollspy";
import { ContainedButton, OutlinedButton, TextButton } from "component/button";
import { useAppDispatch, useAppSelector } from "store/store";
import { setPostJobPreviewRedux } from "store/slice/employer.slice";
import FormLayout from "component/form";
import AutocompleteInput from "forms/hoc/formfield/AutocompleteInput";
import { commonService } from "forms/helpers/common.service";
import { useCallback, useEffect, useState } from "react";
import { Option } from "constant/options";
import { Failed, Success } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import QuillInput from "forms/hoc/formfield/QuillInput";
import { IS_FEATURED } from "constant/options";

const PostJob = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [jobType, setJobType] = useState<Array<Option>>([]);
  const [locations, setLocations] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedBenefits, setSelectedBenefits] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const user = useAppSelector((state) => state?.user?.user);
  const { id } = useParams();

  const [jobDescription, setJobDescription] = useState("");
  const [jobResponsibility, setJobResponsibility] = useState("");
  const [jobQualification, setJobQualification] = useState("");

  const ids = [
    "job-details",
    "job-description",
    "roles-and-responsibilities",
    "required-qualifications",

    "skills-and-competencies",
    "benefits-and-perks",
    "documents-required",
  ];
  const formSections = [
    {
      id: "job-details",
      label: "Job Details",
    },
    {
      id: "job-description",
      label: "Job Description",
    },
    {
      id: "roles-and-responsibilities",
      label: "Roles & Responsibilities",
    },
    {
      id: "required-qualifications",
      label: "Required Qualifications",
    },
 
    {
      id: "skills-and-competencies",
      label: "Skills & Competencies",
    },
    {
      id: "benefits-and-perks",
      label: " Benefits & Perks",
    },
    {
      id: "documents-required",
      label: "Documents Required",
    },
  ];
  const activeId = useScrollspy(ids, 250);
 
  const options = [
    {
      name: "Option 1",
      value: "option1",
    },
  ];
  const initialValues = {
    title: null,
    category: null,
    salary_low: null,
    salary_high: null,
    experience_low: null,
    experience_high: null,
    type: null,
    job_location: [],
    target_date: null,
    description: null,
    responsibility: null,
    qualification: null,
  
    skills: [],
    benefits: [],
    has_resume: false,
    has_cv: false,
    opening: null,
    is_featured: null,
  };

  const handleSubmitJob = () => {
    const locationValues = locations.filter((location: any) =>
      values?.job_location?.includes(location?.value)
    );



    dispatch(
      setPostJobPreviewRedux({
        ...values,
        job_location: locationValues,
        is_update: id ? true : false,
      })
    );
    navigate("/employer/job-preview");
  };

  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    setUpdateValue,
    setPatchValue,
  } = useForm(initialValues, handleSubmitJob, JobPostValidation);

  const handleClickScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    getLocations();
    getMasterData();
    getCategories();
    getSkills();
    getBenefits();
    if (id) {
      getJobDetails();
    }
  }, []);

  const getJobDetails = () => {
    commonService
      .getServices(`job/${id}`)
      .then((response) => {
        setIsLoading(false);
        setPatchValue({
     
          _id: response?.data?.data?.job[0]._id,
          title: response?.data?.data?.job[0].title,
          category: response?.data?.data?.job[0].category,
          salary_low: response?.data?.data?.job[0].salary_low,
          salary_high: response?.data?.data?.job[0].salary_high,
          experience_low: response?.data?.data?.job[0].experience_low,
          experience_high: response?.data?.data?.job[0].experience_high,
          type: response?.data?.data?.job[0].type,
          target_date: dayjs(response?.data?.data?.job[0]?.target_date),
          job_location: response?.data?.data?.job[0]?.job_location?.map(
            (location: any) => location.value
          ),
          description: response?.data?.data?.job[0].description,
          responsibility: response?.data?.data?.job[0].responsibility,
          qualification: response?.data?.data?.job[0].qualification,
          skills: response?.data?.data?.job[0].skills,
          benefits: response?.data?.data?.job[0].benefits,
          opening: response?.data?.data?.job[0].opening,
          is_featured: response?.data?.data?.job[0].is_featured
            ? {
                name: "Yes",
                value: "yes",
              }
            : {
                name: "No",
                value: "no",
              },
          has_resume:
            response?.data?.data?.job[0]?.documents[0]?.name === "Resume" &&
            response?.data?.data?.job[0]?.documents[0]?.required
              ? true
              : false,
          has_cv:
            response?.data?.data?.job[0]?.documents[1]?.name ===
              "Cover Letter" &&
            response?.data?.data?.job[0]?.documents[1]?.required
              ? true
              : false,
        });
        setSelectedSkills(response?.data?.data?.job[0]?.skills);
        setSelectedBenefits(response?.data?.data?.job[0]?.benefits);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getLocations = () => {
    const params = {
      page: 1,
      per_page: 800,
      sort_order: "ASC",
      sort_by: "created_at",
    };
    commonService
      .postServiceWithParams(`master/locations`, null, params)
      .then((response) => {
        setIsLoading(false);
        const jobLocations = response?.data?.data?.results?.map(
          (location: any) => {
            return {
              name: location?.city,
              value: location?._id,
            };
          }
        );
        setLocations(jobLocations);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getMasterData = () => {
    setIsLoading(true);
    commonService
      .getServices(`master?type=job_type`)
      .then(async (response) => {
        setIsLoading(false);
        setJobType(response?.data?.data?.job_type);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getCategories = () => {
    commonService
      .postService(`master/categories`, null)
      .then((response) => {
        setIsLoading(false);
        const jobCategories = response?.data?.data?.results?.map(
          (category: any) => {
            return {
              name: category?.name,
              value: category?._id,
            };
          }
        );
        setCategories(jobCategories);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getSkills = () => {
    commonService
      .postService(`master/skills?per_page=100`, null)
      .then((response) => {
        setIsLoading(false);
        const jobSkills = response?.data?.data?.results?.map((skill: any) => {
          return {
            name: skill?.name,
            value: skill?._id,
          };
        });
        setSkills(jobSkills);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getBenefits = () => {
    commonService
      .postService(`master/benefits?per_page=100`, null)
      .then((response) => {
        setIsLoading(false);
        const jobBenefits = response?.data?.data?.results?.map(
          (benefit: any) => {
            return {
              name: benefit?.name,
              value: benefit?._id,
            };
          }
        );
        setBenefits(jobBenefits);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getObject = (key: string, arr: any) => {
    const obj = arr.find((val: any) => val.value === key);
    return obj;
  };

  const handleSaveJobAsDraft = () => {
    let payload: any = {
      ...values,
      type: values?.type?.value,
      is_featured: values?.is_featured === "yes" ? true : false,
      category: values?.category?.value,
      documents: [],
      employer_id: user?.user?.id,
      skills: values?.skills?.map((skill: any) => skill?.value),
      benefits: values?.benefits?.map((benefit: any) => benefit?.value),
      job_location: values?.job_location,
      status: "draft",
    };

    

    if (values?.has_resume) {
      payload.documents.push({ name: "Resume", required: true });
    
    }

    if (values?.has_cv) {
      payload.documents.push({ name: "Cover Letter", required: true });
     
    }

    const { has_resume, has_cv, ...newPayload } = payload;


    commonService
      .postService("/job", newPayload)
      .then((response: any) => {
       
        Success("Job saved as draft successfully!");
        navigate("/employer/my-jobs", { state: { isDraft: true } });
      })
      .catch((error: any) => {
        Failed(errorMessage("Something went wrong!", error));
      });
  };

  const handleDescriptionChange = useCallback(
    ($e: any) => {
      setJobDescription($e);
      setUpdateValue("description", $e);
    },
    [setJobDescription]
  );

  const handleResponsibilityChange = useCallback(
    ($e: any) => {
      setJobResponsibility($e);
      setUpdateValue("responsibility", $e);
    },
    [setJobResponsibility]
  );

  const handleQualificationsChange = useCallback(
    ($e: any) => {
      setJobQualification($e);
      setUpdateValue("qualification", $e);
    },
    [setJobQualification]
  );

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ my: 3 }}>
        <Link
          underline="hover"
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/employer/home");
          }}
        >
          Home
        </Link>
        <Link
          underline="hover"
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/employer/my-jobs");
          }}
        >
          My Jobs
        </Link>
        <Typography fontWeight={500} color="text.primary">
          Add Job
        </Typography>
      </Breadcrumbs>
      <FormLayout handleSubmit={handleSubmit} isLogin={false}>
        <Box display={"flex"} gap={3}>
          <Box display={{ xs: "none", md: "block" }}>
            <List
              dense={true}
              sx={{
                width: "250px",
                position: "sticky",
                top: "17%",
              }}
            >
              {formSections.map((section) => (
                <ListItem
                  className={section.id === activeId ? "active" : "none"}
             
                  sx={{
                    height: "44px",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        component={"a"}
                        href={`#${section.id}`}
                        variant="h4"
                        fontWeight={500}
                        sx={{
                          textDecoration: "none",
                        }}
                      >
                        {section.label}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Box flex={1}>
            <Card
              id="job-details"
              sx={{
                boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
              }}
            >
              <CardContent>
                <Typography variant="subtitle2" fontWeight={600}>
                  Job Details
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={4}>
                  <Grid item xs={12} pt={1}>
                    <InputField
                      fieldName="Job Title"
                      values={values?.title || ""}
                      name="title"
                      errors={errors?.title}
                      handleChange={handleChange}
                      placeholder="Job Title"
                      id="title"
                      isRequired={false}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ paddingTop: "8px !important" }}
                  >
                    <SelectField
                      fieldName="Job Category"
                      values={values?.category?.value || ""}
                      name="category"
                      isRequired={false}
                      errors={errors?.category}
                      handleChange={($e: any) => {
                        setUpdateValue(
                          "category",
                          getObject($e.target.value, categories)
                        );
                      }}
                      placeholder="Job Category"
                      options={categories}
                      id="category"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ paddingTop: "8px !important" }}
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <InputField
                          fieldName="Salary Range From(LPA)"
                          values={values?.salary_low || ""}
                          name="salary_low"
                          errors={errors?.salary_low}
                          handleChange={($e: any) => {
                            setUpdateValue(
                              "salary_low",
                              parseFloat($e.target.value)
                            );
                          }}
                          placeholder="Salary Range From(LPA)"
                          id="salary_low"
                          isRequired={false}
                          onlyDecimalNumber={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputField
                          fieldName="Salary Range To(LPA)"
                          values={values?.salary_high || ""}
                          name="salary_high"
                          errors={errors?.salary_high}
                          handleChange={($e: any) => {
                            setUpdateValue(
                              "salary_high",
                              parseFloat($e.target.value)
                            );
                          }}
                          placeholder="Salary Range To(LPA)"
                          id="salary_high"
                          isRequired={false}
                          onlyDecimalNumber={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ paddingTop: "8px !important" }}
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <InputField
                          fieldName="Work experience from"
                          values={values?.experience_low || ""}
                          name="experience_low"
                          errors={errors?.experience_low}
                          handleChange={($e: any) => {
                            setUpdateValue(
                              "experience_low",
                              parseFloat($e.target.value)
                            );
                          }}
                          placeholder="Work experience from"
                          id="experience_low"
                          isRequired={false}
                          onlyNumber={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputField
                          fieldName="Work experience To"
                          values={values?.experience_high || ""}
                          name="experience_high"
                          errors={errors?.experience_high}
                          handleChange={($e: any) => {
                            setUpdateValue(
                              "experience_high",
                              parseFloat($e.target.value)
                            );
                          }}
                          placeholder="Work experience To"
                          id="experience_high"
                          isRequired={false}
                          onlyNumber={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ paddingTop: "8px !important" }}
                  >
                    <SelectField
                      fieldName="Job Type"
                      values={values?.type?.value || ""}
                      name="type"
                      isRequired={false}
                      errors={errors?.type}
                      handleChange={($e: any) => {
                        setUpdateValue(
                          "type",
                          getObject($e.target.value, jobType)
                        );
                      }}
                      placeholder="Job Type"
                      options={jobType}
                      id="type"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ paddingTop: "8px !important" }}
                  >
                    <MultiSelectField
                      fieldName="Job locations"
                      values={values?.job_location || ""}
                      name="job_location"
                      isRequired={false}
                      multiple={true}
                      errors={errors?.job_location}
                      handleChange={($e: any) => {
                        setUpdateValue("job_location", $e?.target?.value);
                        
                      }}
                      placeholder="Job locations"
                      options={locations}
                      id="job_location"
                    />
                  
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{ paddingTop: "8px !important" }}
                  >
                    <InputDatePicker
                      fieldName="Apply before"
                      values={dayjs(values?.target_date) || ""}
                      name="target_date"
                      errors={errors.target_date}
                      handleChange={(e) =>
                        setUpdateValue(
                          "target_date",
                          dayjs(e).format("YYYY-MM-DD")
                        )
                      }
                      placeholder="Apply by(DD/MM/YYYY)"
                      minDate={dayjs()}
                      isRequired={false}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ paddingTop: "8px !important" }}>
                    <InputField
                      fieldName="Job Openings"
                      values={values?.opening || ""}
                      name="Openings"
                      errors={errors?.opening}
                      handleChange={($e: any) => {
                        setUpdateValue("opening", parseFloat($e.target.value));
                      }}
                      placeholder="Job Openings"
                      id="opening"
                      isRequired={false}
                      onlyNumber={true}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ paddingTop: "8px !important" }}>
                    <SelectField
                      fieldName="Featured Job"
                      values={values?.is_featured?.value || ""}
                      name="is_featured"
                      isRequired={false}
                      errors={errors?.is_featured}
                      handleChange={($e: any) => {
                        setUpdateValue(
                          "is_featured",
                          getObject($e.target.value, IS_FEATURED)
                        );
                      }}
                      placeholder="Featured Job"
                      options={IS_FEATURED}
                      id="is_featured"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card
              id="job-description"
              sx={{
                mt: 2,
                boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
              }}
            >
              <CardContent>
                <Typography variant="subtitle2" fontWeight={600}>
                  Job Description
                </Typography>
                <Divider sx={{ my: 2 }} />
             
                <QuillInput
                  value={values?.description || ""}
                  errors={errors?.description}
                  // handleChange={($e: any) => {
                  //   setUpdateValue("description", $e);
                  // }}
                  handleChange={handleDescriptionChange}
                />
              </CardContent>
            </Card>
            <Card
              id="roles-and-responsibilities"
              sx={{
                mt: 2,
                boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
              }}
            >
              <CardContent>
                <Typography variant="subtitle2" fontWeight={600}>
                  Roles & Responsibilities
                </Typography>
                <Divider sx={{ my: 2 }} />
                <QuillInput
                  value={values?.responsibility || ""}
                  errors={errors?.responsibility}
                  // handleChange={($e: any) => {
                  //   setUpdateValue("responsibility", $e);
                  // }}
                  handleChange={handleResponsibilityChange}
                />
              </CardContent>
            </Card>
            <Card
              id="required-qualifications"
              sx={{
                mt: 2,
                boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
              }}
            >
              <CardContent>
                <Typography variant="subtitle2" fontWeight={600}>
                  Required Qualifications
                </Typography>
                <Divider sx={{ my: 2 }} />
                <QuillInput
                  value={values?.qualification || ""}
                  errors={errors?.qualification}
                  // handleChange={($e: any) => {
                  //   setUpdateValue("qualification", $e);
                  // }}
                  handleChange={handleQualificationsChange}
                />
              </CardContent>
            </Card>
            <Card
              id="skills-and-competencies"
              sx={{
                mt: 2,
                boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
              }}
            >
              <CardContent>
                <Typography variant="subtitle2" fontWeight={600}>
                  Skills & Competencies
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                  
                    <AutocompleteInput
                      options={skills}
                      name="skills"
                      value={selectedSkills}
                      errors={errors?.skills}
                      placeholder={"Search Skills & Competencies"}
                      masterType={"skill"}
                      setOptions={setSkills}
                      setValue={(val: any) => {
                        setSelectedSkills(val);
                    
                        setUpdateValue("skills", val);
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card
              id="benefits-and-perks"
              sx={{
                mt: 2,
                boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
              }}
            >
              <CardContent>
                <Typography variant="subtitle2" fontWeight={600}>
                  Benefits & Perks
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <AutocompleteInput
                      options={benefits}
                      name="benefits"
                      errors={errors?.benefits}
                      value={selectedBenefits}
                      placeholder={"Search Benefits & Perks"}
                      setOptions={setBenefits}
                      masterType={"benefit"}
                      setValue={(val: any) => {
                        setSelectedBenefits(val);
                        setUpdateValue("benefits", val);
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card
              id="documents-required"
              sx={{
                mt: 2,
                mb: 4,
                boxShadow: `0px 4px 12px 0px ${theme?.palette?.primary?.boxShadowLight}`,
              }}
            >
              <CardContent>
                <Typography variant="subtitle2" fontWeight={600}>
                  Documents Required
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        name="has_resume"
                        control={
                          <Checkbox
                            checked={values?.has_resume}
                            value={values?.has_resume || null}
                            onClick={($e: any) => {
                              setUpdateValue("has_resume", !values?.has_resume);
                            }}
                          />
                        }
                        label="Resume"
                      />
                      <FormControlLabel
                        name="has_cv"
                        control={
                          <Checkbox
                            checked={values?.has_cv}
                            value={values?.has_cv || null}
                            onClick={($e: any) => {
                              setUpdateValue("has_cv", !values?.has_cv);
                            }}
                          />
                        }
                        label="Cover letter"
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Box>
        <Grid container spacing={4}>
      
          <Grid
            item
            xs={12}
            display={"flex"}
            flexDirection={{ xs: "column", sm: "row" }}
            mb={3}
            gap={{ xs: 2, sm: 4 }}
            justifyContent={"space-between"}
          >
            <OutlinedButton
              size={"small"}
              isBold={true}
              label={"Cancel"}
              handleClick={() => navigate("/employer/my-jobs")}
            />
            <Box
              display={"flex"}
              gap={{ xs: 2, sm: 4 }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              {!id && (
                <OutlinedButton
                  size={"small"}
                  isBold={true}
                  label={"Save As Draft"}
                  handleClick={handleSaveJobAsDraft}
                />
              )}

              <ContainedButton
                size="small"
                isBold={true}
                label={"View Summary"}
                type="submit"
              />
            </Box>
          </Grid>
        </Grid>
      </FormLayout>
    </>
  );
};

export default PostJob;
