import React, { useEffect, useState } from "react";

import {
  Accordion,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { InputField, SelectField } from "forms/hoc/formfield";
import { ContainedButton } from "component/button";
import LocationColorIcon from "asset/icons/location-color.svg";
import ExperienceColorIcon from "asset/icons/experience-color.svg";
import useForm from "forms/hooks/useForm";
import { SearchValidationSchema } from "forms/hooks/validateRules";
import SearchIcon from "asset/icons/search.svg";

import SortIcon from "asset/icons/sort.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CandidateCard from "forms/hoc/candidate-card";
import PaginationComponent from "component/pagination";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreFilters from "forms/hoc/filters/more-filter";
import CandiateDetails from "./candidate-details";
import FilterIcon from "asset/icons/filter.svg";
import { commonService } from "forms/helpers/common.service";
import { Failed } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import Loader from "ui-component/Loader";
import { Dropdown } from "component/dropdown";
import { EXPERIENCE_LEVEL, SALARY_RANGE, Option } from "constant/options";
import Sort from "forms/hoc/filters/sort";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NoDataFoundImage from "asset/images/no-search-results.svg";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CandidateList from "./candidate-list";
import SweetAlertComponent from "component/HOC/Alert";

const AllCandidates = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  const initialValues = {
    preferred_department: null,
    preferred_location: null,
    search: null,
    work_experience_low: null,
    work_experience_high: null,
    joining_type: null,
    salary_low: null,
    salary_high: null,
    top_companies: null,
    member_since: null,
  };
  const handleSearchSubmit = () => {
  
    navigate("/employer/all-candidates");
    setPage(0);
    getCandidates(values);
  };
  const [sortBy, setSortBy] = useState("updated_at");

  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    setUpdateValue,
    resetForm,
  } = useForm(initialValues, handleSearchSubmit, SearchValidationSchema);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState<any>(null);
  const [selectedCandidateDetails, setSelectedCandidateDetails] =
    useState<any>(null);
  const allCategories = [
    {
      name: "category1",
      value: "category1",
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [allCandidates, setAllCandidates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(12);
  const [total, setTotal] = useState(0);
  const [jobType, setJobType] = useState<Array<Option>>([]);
  const [moreFilters, setMoreFilters] = useState(false);
  const [sort, setSort] = useState(false);
  const [locations, setLocations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [posted, setPosted] = useState([]);
  const [openFilters, setOpenFilters] = useState(false);
  const { state } = useLocation();
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    filterType: string
  ) => {
    setAnchorEl(event.currentTarget);
    switch (filterType) {
      case "sort":
        setSort(true);
        break;
      case "more_filters":
        setMoreFilters(true);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMoreFilters(false);
    setSort(false);
  };

  const [moreFiltersAnchorEl, setMoreFiltersAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const moreFiltersOpen = Boolean(moreFiltersAnchorEl);
  const handleMoreFiltersClick = (event: React.MouseEvent<HTMLElement>) => {
    setMoreFiltersAnchorEl(event.currentTarget);
  };
  const handleMoreFiltersClose = () => {
    setMoreFiltersAnchorEl(null);
  };
  // table functions
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    getMasterData();
    getLocations();
    getDepartments();
  }, []);

  useEffect(() => {
    if (id) {
      getCandidateDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (Object.values(values).some((value) => value)) {
      getCandidates(values);
    } else {
      setUpdateValue(
        "preferred_location",
        state?.location ? [state?.location] : null
      );
      setUpdateValue(
        "preferred_department",
        state?.department ? [state?.department] : null
      );
      setUpdateValue("search", state?.search);
      const filters = {
        preferred_location: state?.location && [state?.location],
        preferred_department: state?.department && [state?.department],
        search: state?.search,
      };
      getCandidates(filters);
    }
  }, [page]);

  const getCandidates = (filters: any, isCleared?: boolean) => {
    setIsLoading(true);
    const filteredFilterValues = Object.fromEntries(
      Object.entries(filters).filter(
        ([key, value]) =>
          key !== "search" && value !== null && value !== undefined
      )
    );
    const payload = {
      ...filteredFilterValues,
    };
    const params: any = {
      page: page + 1,
      per_page: perPage,
      sort_order: "DESC",
      search_by: filters?.search ? "title,category.name,skill.name" : "",
      search: filters?.search,
    };
    if (sortBy) {
      params.sort_by = sortBy;
    }
    commonService
      .postServiceWithParams(
        `candidate/candidates`,
        isCleared ? null : payload,
        params
      )
      .then((response) => {
        setIsLoading(false);
        if (id) {
          const remainingCandidates = response?.data?.data?.results?.map(
            (candidate: any) => {
              if (candidate._id !== id) {
                return {
                  ...candidate,
                  skills: candidate?.skill,
                };
              }
            }
          );
          setAllCandidates(remainingCandidates);
        } else {
          const candidates = response?.data?.data?.results?.map(
            (candidate: any) => {
              return {
                ...candidate,
                skills: candidate?.skill,
              };
            }
          );
          setAllCandidates(candidates);
        }

        setTotal(response?.data?.data?.pagination?.total);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getCandidateDetails = (id: any) => {
    setIsLoading(true);
    commonService
      .getServices(`candidate/${id}?fieldset=all`)
      .then((response) => {
        setIsLoading(false);
        setSelectedCandidateDetails({
          ...response?.data?.data[0],
          skills: response?.data?.data[0].skill,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getMasterData = () => {
    setIsLoading(true);
    commonService
      .getServices(`master?type=joining_type`)
      .then(async (response) => {
        setIsLoading(false);
        setJobType(response?.data?.data?.joining_type);
      })
      .catch((error) => {
        setIsLoading(false);
   
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getLocations = () => {
    setIsLoading(true);
    const params = {
      page: 1,
      per_page: 800,
      sort_order: "ASC",
      sort_by: "created_at",
    };
    commonService
      .postServiceWithParams(`master/locations`, null, params)
      .then((response) => {
        setIsLoading(false);
        const jobLocations = response?.data?.data?.results?.map(
          (location: any) => {
            return {
              name: location?.city,
              value: location?._id,
            };
          }
        );
        setLocations(jobLocations);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const getDepartments = () => {
    setIsLoading(true);
    commonService
      .postService(`master/categories`, null)
      .then((response) => {
        setIsLoading(false);
        const jobCategories = response?.data?.data?.results?.map(
          (category: any) => {
            return {
              name: category?.name,
              value: category?._id,
            };
          }
        );
        setDepartments(jobCategories);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  const onResetForm = () => {
    resetForm();
    setPage(0);
    setSortBy("");
    getCandidates({}, true);
    navigate("/employer/all-candidates");
  };

  return (
    <>
      {isLoading && <Loader />}
      <Box
        mx={{ xs: "-2%", md: "-7.5rem" }}
        px={{ xs: "2%", md: "7.5rem" }}
        py={2}
        boxShadow={{ sm: "0px 4px 8px 0px #1D28581A" }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/employer/home");
            }}
          >
            Home
          </Link>

          {!id && (
            <Typography fontWeight={500} color="text.primary">
              Employer - All candidates
            </Typography>
          )}
          {id && (
            <Link
              underline="hover"
              color="inherit"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/employer/all-candidates");
              }}
            >
              Employer - All candidates
            </Link>
          )}
          {id && (
            <Typography
              maxWidth={{ xs: "118px", md: "100%" }}
              sx={{
                overflow: "hidden",
                display: "inline-block",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              fontWeight={500}
              color="text.primary"
            >
              {selectedCandidateDetails?.first_name
                ? selectedCandidateDetails?.first_name +
                  " " +
                  selectedCandidateDetails?.last_name
                : ""}
            </Typography>
          )}
        </Breadcrumbs>
        {!selectedCandidateDetails && (
          <Box display={{ xs: "block", sm: "none" }}>
            <form onSubmit={handleSubmit}>
              <Box
                onClick={() => {
                  setFilterOpen(true);
                }}
                mt={{ xs: 1, sm: 3 }}
                mb={{ xs: 2, sm: 3 }}
                sx={{ display: { xs: "block", sm: "none" } }}
              >
                <InputField
                  values={values?.search || ""}
                  name="search"
                  errors={errors?.search}
                  handleChange={() => {}}
                  placeholder="Search Candidates"
                  id="search"
                  adorament="startAdornment"
                  position="start"
                  Icon={<img src={SearchIcon} alt="Search" />}
                />
              </Box>
              <Box display={"flex"} justifyContent={"end"} gap={2}>
                <IconButton
                  sx={{
                    borderRadius: "8px",
                    border: `1px solid ${theme?.palette?.primary?.borderOutline}`,
                  }}
                  aria-label="delete"
                  size="medium"
                  onClick={() => {
                    setOpenFilters((value) => !value);
                  }}
                >
                  <img src={FilterIcon} alt="Sort" />
                </IconButton>
                <IconButton
                  sx={{
                    borderRadius: "8px",
                    border: `1px solid ${theme?.palette?.primary?.borderOutline}`,
                  }}
                  aria-label="delete"
                  size="medium"
                  onClick={($e) => {
                    $e.preventDefault();
                    onResetForm();
                  }}
                >
                  <ClearRoundedIcon
                    sx={{
                      height: "24px",
                      width: "24px",
                      color: theme?.palette?.primary?.iconOrange,
                    }}
                  />
                </IconButton>
                <ContainedButton
                  size="large"
                  label="Search Candidates"
                  type="submit"
                  isBold={true}
                  isFullWidth={false}
                />
              </Box>
              {openFilters && (
                <Box display={"flex"} gap={1} flexWrap={"wrap"} mt={2}>
                  <Dropdown
                    label="Experience Level"
                    options={EXPERIENCE_LEVEL}
                    id="experience_level"
                    isActive={
                      values?.work_experience_low ||
                      values?.work_experience_high
                        ? true
                        : false
                    }
                    selected={
                      values?.work_experience_high
                        ? `${values.work_experience_low}-${values.work_experience_high}`
                        : values?.work_experience_low?.toString()
                    }
                    handleSelect={(value: any) => {
                      const experiences = value?.split("-");
                      if (experiences.length === 1)
                        setUpdateValue("work_experience_low", parseInt(value));
                      setUpdateValue("work_experience_high", null);
                      if (experiences.length === 2)
                        setUpdateValue(
                          "work_experience_low",
                          parseInt(experiences[0])
                        );
                      setUpdateValue(
                        "work_experience_high",
                        parseInt(experiences[1])
                      );
                    }}
                  />
                  <Dropdown
                    label="Joining type"
                    options={jobType}
                    isActive={values.joining_type?.length > 0 ? true : false}
                    id="joining_type"
                    selectedItems={values?.joining_type}
                    isMultiSelect={true}
                    handleSelect={(value: any) => {
                      setUpdateValue("joining_type", [...value]);
                    }}
                  />
                  <Dropdown
                    label="Salary range"
                    options={SALARY_RANGE}
                    isActive={values.salary_low ? true : false}
                    selected={
                      values?.salary_high
                        ? `${values.salary_low}-${values.salary_high}`
                        : values?.salary_low?.toString()
                    }
                    id="salary_range"
                    handleSelect={(value: any) => {
                      const salaries = value?.split("-");
                      if (salaries.length === 1)
                        setUpdateValue("salary_low", value);
                      if (salaries.length === 2)
                        setUpdateValue("salary_low", salaries[0]);
                      setUpdateValue("salary_high", salaries[1]);
                    }}
                  />

                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    {moreFilters && (
                      <MoreFilters
                        hasTopCompanies={false}
                        selectedCompanies={values.top_companies}
                        selectedPosted={posted}
                        handleTopComaniesChange={(values: any) => {
                          setUpdateValue("top_companies", [...values]);
                        }}
                        handlePostedChange={(values: any) => {
                          setPosted(values);
                          var currentDate = new Date();
                          let postedValues: any = {};
                          if (values.length > 0) {
                            if (values.indexOf("last-7-days") > 0) {
                              var date7DaysAgo = new Date(currentDate);
                              date7DaysAgo.setDate(date7DaysAgo.getDate() - 7);
                              postedValues.start_date =
                                formatDate(date7DaysAgo);
                              postedValues.end_date = formatDate(currentDate);
                            } else {
                              postedValues.start_date = formatDate(currentDate);
                              postedValues.end_date = formatDate(currentDate);
                            }
                            setUpdateValue(
                              "start_date",
                              postedValues?.start_date
                            );
                            setUpdateValue("end_date", postedValues?.end_date);
                          } else {
                            setUpdateValue("start_date", null);
                            setUpdateValue("end_date", null);
                          }
                        }}
                      />
                    )}
                    {sort && (
                      <Sort
                        handleSortSelect={(type: string, value: string) => {
                          setSortBy(value);
                     
                          handleClose();
                        }}
                        selectedSort={sortBy}
                      />
                    )}
                  </Menu>
                </Box>
              )}
            </form>
          </Box>
        )}

        <Box display={{ xs: "none", sm: "block" }}>
          <form onSubmit={handleSubmit}>
            <Card>
              <CardContent sx={{ px: 0, py: "1rem !important" }}>
                <Grid spacing={2} container sx={{ px: 0 }}>
                  <Grid item xs={6}>
                    <InputField
                      values={values?.search || ""}
                      name="search"
                      errors={errors?.search}
                      handleChange={handleChange}
                      placeholder="Search by skills, designation"
                      id="search"
                      adorament="startAdornment"
                      position="start"
                      Icon={<img src={SearchIcon} alt="Search" />}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SelectField
                      values={values.preferred_department}
                      name="preferred_department"
                      errors={""}
                      handleChange={(e: any) => {
                        setUpdateValue("preferred_department", [
                          e.target.value,
                        ]);
                      }}
                      options={departments}
                     
                      placeholder="All departments"
                      id="preferred_department"
                      isRequired={false}
                      adorament="startAdornment"
                      position="start"
                      Icon={<img src={ExperienceColorIcon} alt="Search" />}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <SelectField
                      values={values.preferred_location}
                      name="preferred_location"
                      errors={""}
                      handleChange={(e: any) => {
                        setUpdateValue("preferred_location", [e.target.value]);
                      }}
                      options={locations}
                      placeholder="Preferred Location"
                      adorament="startAdornment"
                      position="start"
                      Icon={<img src={LocationColorIcon} alt="Search" />}
                      isRequired={false}
                      id="preferred_location"
                    />
                  </Grid>

                  {/* <Grid xs={1.4} pl={2} pt={2}></Grid> */}
                </Grid>
              </CardContent>
            </Card>
            <Divider />
            <Box
              pt={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={2}
            >
           
              <Box display={"flex"} gap={3}>
                <Dropdown
                  label="Experience Level"
                  options={EXPERIENCE_LEVEL}
                  id="experience_level"
                  isActive={
                    values.work_experience_low || values.work_experience_high
                      ? true
                      : false
                  }
                  selected={
                    values?.work_experience_high
                      ? `${values.work_experience_low}-${values.work_experience_high}`
                      : values?.work_experience_low?.toString()
                  }
                  handleSelect={(value: any) => {
                    const experiences = value?.split("-");
                    if (experiences.length === 1)
                      setUpdateValue("work_experience_low", parseInt(value));
                    setUpdateValue("work_experience_high", null);
                    if (experiences.length === 2)
                      setUpdateValue(
                        "work_experience_low",
                        parseInt(experiences[0])
                      );
                    setUpdateValue(
                      "work_experience_high",
                      parseInt(experiences[1])
                    );
                  }}
                />
                <Dropdown
                  label="Joining type"
                  options={jobType}
                  selectedItems={values?.joining_type}
                  isActive={values?.joining_type?.length > 0 ? true : false}
                  id="joining_type"
                  isMultiSelect={true}
                  handleSelect={(value: any) => {
                    setUpdateValue("joining_type", [...value]);
                  }}
                />
                <Dropdown
                  label="Salary range"
                  options={SALARY_RANGE}
                  isActive={values.salary_low ? true : false}
                  selected={
                    values?.salary_high
                      ? `${values.salary_low}-${values.salary_high}`
                      : values?.salary_low?.toString()
                  }
                  id="salary_range"
                  handleSelect={(value: any) => {
                    const salaries = value?.split("-");
                    if (salaries.length === 1)
                      setUpdateValue("salary_low", value);
                    if (salaries.length === 2)
                      setUpdateValue("salary_low", salaries[0]);
                    setUpdateValue("salary_high", salaries[1]);
                  }}
                />
              
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  {moreFilters && (
                    <MoreFilters
                      hasTopCompanies={false}
                      selectedCompanies={values.top_companies}
                      selectedPosted={posted}
                      handleTopComaniesChange={(values: any) => {
                        setUpdateValue("top_companies", [...values]);
                      }}
                      handlePostedChange={(values: any) => {
                      
                        setPosted(values);
                        var currentDate = new Date();
                        let postedValues: any = {};
                        if (values.length > 0) {
                          if (values.indexOf("last-7-days") > 0) {
                            var date7DaysAgo = new Date(currentDate);
                            date7DaysAgo.setDate(date7DaysAgo.getDate() - 7);
                            postedValues.start_date = formatDate(date7DaysAgo);
                            postedValues.end_date = formatDate(currentDate);
                          } else {
                            postedValues.start_date = formatDate(currentDate);
                            postedValues.end_date = formatDate(currentDate);
                          }
                          setUpdateValue(
                            "start_date",
                            postedValues?.start_date
                          );
                          setUpdateValue("end_date", postedValues?.end_date);
                        } else {
                          setUpdateValue("start_date", null);
                          setUpdateValue("end_date", null);
                        }
                      }}
                    />
                  )}
                  {sort && (
                    <Sort
                      handleSortSelect={(type: string, value: string) => {
                        setSortBy(value);
                      
                        handleClose();
                      }}
                      selectedSort={sortBy}
                    />
                  )}
                </Menu>
              </Box>
              <Box display={"flex"} gap={3}>
                <ContainedButton
                  size="large"
                  label="Search Candidates"
                  type="submit"
                  isBold={true}
                  isFullWidth={false}
                  handleClick={() => {
                    handleSearchSubmit();
                  }}
                />

                <IconButton
                  sx={{
                    borderRadius: "8px",
                    border: `1px solid ${theme?.palette?.primary?.borderOutline}`,
                  }}
                  aria-label="delete"
                  size="medium"
                  onClick={($e) => {
                    $e.preventDefault();
                    onResetForm();
                  }}
                >
                  <ClearRoundedIcon sx={{ height: "24px", width: "24px" }} />
                </IconButton>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>

      {id ? (
        <Box my={3}>
          {selectedCandidateDetails ? (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} display={{ xs: "none", sm: "block" }}>
                <Box display={"flex"} flexDirection={"column"} gap={3}>
                  {selectedCandidateDetails && (
                    <CandidateCard
                      isActive={true}
                      candidate={selectedCandidateDetails}
                    />
                  )}
                  {allCandidates?.map((candidate: any) => {
                    if (candidate?._id !== id) {
                      return (
                        <CandidateCard
                          isActive={id === candidate?._id}
                          candidate={candidate}
                          handleCandidateClick={() => {
                            setSelectedCandidate(candidate?._id);
                            navigate(
                              `/employer/candidate-details/${candidate?._id}`
                            );
                          }}
                        />
                      );
                    }
                  })}
                </Box>
                <Box>
                  <PaginationComponent
                    page={page}
                    rowsPerPage={perPage}
                    total={total}
                    onChange={handleChangePage}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                {selectedCandidateDetails && (
                  <CandiateDetails details={selectedCandidateDetails} />
                )}
              </Grid>
            </Grid>
          ) : (
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              my={3}
            >
              <img src={NoDataFoundImage} alt="No Data Found" />
              <Typography
                variant="subtitle1"
                fontWeight={600}
                color={theme?.palette?.primary?.textBtn}
              >
                Oh! No candidates available
              </Typography>
              <Typography
                maxWidth={"471px"}
                variant="h5"
                fontWeight={500}
                color={theme?.palette?.primary?.textGrey}
                textAlign={"center"}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                semper nunc erat, ut auctor lorem vulputate a.
              </Typography>
            </Box>
          )}
        </Box>
      ) : allCandidates?.length > 0 ? (
        <CandidateList
          candidatesList={allCandidates}
          handleSelectJob={(id: string) => {
            navigate(`/employer/candidate-details/${id}`);
          }}
          handleChangePage={handleChangePage}
          paginationData={{ page: page, perPage: perPage, total: total }}
        />
      ) : (
        isLoading === false && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            my={3}
          >
            <img src={NoDataFoundImage} alt="No Data Found" />
            <Typography
              variant="subtitle1"
              fontWeight={600}
              color={theme?.palette?.primary?.textBtn}
            >
              Oh! No candidates available
            </Typography>
            <Typography
              maxWidth={"471px"}
              variant="h5"
              fontWeight={500}
              color={theme?.palette?.primary?.textGrey}
              textAlign={"center"}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
              semper nunc erat, ut auctor lorem vulputate a.
            </Typography>
          </Box>
        )
      )}
    </>
  );
};
export default AllCandidates;

// Function to format date as "YYYY-MM-DD"
function formatDate(date: Date) {
  var year = date.getFullYear();
  var month = padZero(date.getMonth() + 1); // Adding 1 because getMonth() returns 0-based month
  var day = padZero(date.getDate());
  return year + "-" + month + "-" + day;
}

// Function to add leading zero if needed
function padZero(num: number) {
  return (num < 10 ? "0" : "") + num;
}
